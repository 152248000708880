import React from "react";

import {
  Row,
  Col,
} from "reactstrap";

function Crisis() {
  return (

      <div className="content">
        <Row>
          <Col md="12">
              <h1>College Teams Page</h1>
          </Col>
        </Row>
        <Row>
          <Col md="12">
          </Col>
        </Row>
      </div>
  );
}

export default Crisis;
